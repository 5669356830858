export const UppercaseFirstLetter = (str: string) => {
  return (str || "").charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const SplitOnUnderscore = (str: string) => {
  return (str || "").split("_").join(" ");
};

export const SplitAndCapitalize = (str: string) => {
  return (str || "")
    .split("_")
    .map((word) => UppercaseFirstLetter(word))
    .join(" ");
};

export const SplitPascal = (str: string) => {
  return (str || "")
    .split(/(?=[A-Z])/)
    .map((word) => UppercaseFirstLetter(word))
    .join(" ");
};

export const URL_REG_EXP =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;

export async function HashObject(obj: Record<string, any>): Promise<string> {
  const str = JSON.stringify(obj, Object.keys(obj).sort());
  const buffer = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", buffer);
  return Array.from(new Uint8Array(hashBuffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}
